import { Component, ViewChild, ElementRef, ViewEncapsulation, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'preview-useful-tips',
    templateUrl: './preview-useful-tips.component.html',
    styleUrls: ['./preview-useful-tips.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PreviewUsefulTipsComponent implements OnInit {

    @ViewChild('dataContainer', { static: true } as any) dataContainer: ElementRef;
    // usefulTips;
    timeout: number;
    autoClose: boolean;
    elementType = 'url';
    // value: any;
    // href: string;

    constructor(
        public dialogRef: MatDialogRef<PreviewUsefulTipsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        console.log('qrcode component constructor');
    }

    loadData(data) {
        if(data)
        this.dataContainer.nativeElement.innerHTML = data;
    }

    ngOnInit() {
        console.log(this.data);
        // this.usefulTips = "<p><b>pluto</b></p><p>ddddfffffff</p><p><strong>pippo</strong></p><ol><li>asssdddee</li><li>bssss</li><li>v</li></ol><p><br></p>";
        /// this.loadData(this.usefulTips);

        if(this.data != undefined && this.data != null) {
            this.loadData(this.data.usefulTips);
            // this.value = this.data.qrcode;
            if(this.data.buttons != undefined && this.data.buttons != null && this.data.buttons.length > 0) {
              this.autoClose = true;
              for (let index = 0; index < this.data.buttons.length; index++) {
                const element = this.data.buttons[index];
                if(element.close != undefined && element.close != null && element.close == true) {
                  this.autoClose = false;
                  break;
                }
              }
            } else {
              this.autoClose = true;
              this.timeout = 3000;
              if(this.data.timeout != undefined && this.data.timeout != null)
                this.timeout = this.data.timeout;
            }
          }
      
          if(this.autoClose == true) {
            setTimeout(()=> {
              this.dialogRef.close();
            }, this.timeout);
          }
    }

    onButtonClick(item) {
        let value = null;
        if (item.value != undefined && item.value != null)
            value = item.value;
        /*
        if(item == undefined || item == null)
          return;
        let value = null;
        if(item.value != undefined && item.value != null)
          value = item.value;
        if(item.callback != undefined && item.callback != null)
          item.callback(value);
        if(item.close != undefined && item.close != null && item.close == true)
          this.dialogRef.close(value);
          */
        if (item.close != undefined && item.close != null && item.close == true)
            this.dialogRef.close(value);
    }
}