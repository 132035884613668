import { ChangeDetectorRef, Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-dynamic-modal-select',
  templateUrl: './dynamic-modal-select.component.html',
  styleUrls: ['./dynamic-modal-select.component.scss']
})
export class DynamicModalSelectComponent implements OnInit {

  dialogRef = null;
  dialogData: any;
  isUsingMatDialog: boolean;
  datas: any;
  item: any;
  val: any;
  onSubmitCallback: any;
  options: any;

  constructor(
    private api: ApiService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private injector: Injector,) {

    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.dialogData = this.injector.get(MAT_DIALOG_DATA, null);
    this.isUsingMatDialog = this.dialogRef != null;

    this.val = null;
    if (this.isUsingMatDialog) {
      console.log('dynamic modal select constructor')
      this.datas = this.dialogData;
      this.item = this.datas.item;

      if (this.datas.onSubmitCallback) {
        this.onSubmitCallback = this.datas.onSubmitCallback;
      }
      if(this.item.multiple) {
        this.val = [];
      }
      
    }

    console.log("item= " + JSON.stringify(this.item));
  }

  ngOnInit() {
  
    if(this.item.optionsFunction && this.item.optionsFunctionParams){
      this.item.optionsFunction(this.item.optionsFunctionParams.specificParameters,
        this.item.optionsFunctionParams.searchKeys,
        this.item.optionsFunctionParams.searchValue,
        this.item.optionsFunctionParams.sortParam,
        this.item.optionsFunctionParams.sortDirection,
        this.item.optionsFunctionParams.pageIndex,
        this.item.optionsFunctionParams.pageSize).then((options) => {
          this.options = options;
          this.item.options = this.options;
          this.checkCurrentVal();
        })
    } else if(this.item.options) {
      this.options = this.item.options;
      this.checkCurrentVal();
    } 
    
  }
  checkCurrentVal(){
    if(this.datas.currentVal) {
      if(!this.item.multiple) {
        this.val = this.options.find((el)=>{ return el.value == this.datas.currentVal.currentId});
      }
      else {
        if(Array.isArray(this.datas.currentVal)) {
          for (let index = 0; index < this.datas.currentVal.length; index++) {
            const element = this.datas.currentVal[index];
            let el = this.options.find((ele)=>{ return ele.value == element.currentId});
            if(el)
              this.val.push(el);
          }
        } else {
          if(this.datas.currentVal.currentId.indexOf(',') > -1) {
            let splittedId = this.datas.currentVal.currentId.split(',');
            for (let index = 0; index < splittedId.length; index++) {
              const element = splittedId[index];
              let el = this.options.find((ele)=>{ return ele.value == element.trim()});
              if(el)
                this.val.push(el);
            }
          } else {
            let el = this.options.find((ele)=>{ return ele.value == this.datas.currentVal.currentId});
            if(el)
              this.val.push(el);
          }
          
          // this.val.push({name : this.datas.currentVal.currentName, value: this.datas.currentVal.currentId })
        }
      }
    }
  }

  filterList(val) {
    if (!val || val.trim() == '') {
      this.options = this.item.options;
      return;
    }
    val = val.trim().toLowerCase();
    this.options = this.item.options.filter((el) => {

      if(el.filter){
        return el.filter.toLowerCase().indexOf(val) > -1  
      }
      else{
        return el.name.toLowerCase().indexOf(val) > -1
      }
    });
  }

  submitForm() {
    if (this.onSubmitCallback) {
      this.onSubmitCallback(this.val);
    }
    this.dialogRef.close(this.val);
  }

  selectOption(option) {
    this.val = option;
  }

  selectChange(option) {
    console.log(option)
  }

  manageCheckboxCheck(checked, obj) {
    if (!this.val)
      this.val = [];
    if (checked) {
      this.val.push(obj);
    }
    else {
      this.val.forEach((item, index) => {
        if (item === obj) {
          this.val.splice(index, 1);
        }
      });
    }
  }

}
