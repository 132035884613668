import { Injectable , Inject} from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class PlatformVariableService 
{

  publicPlatformVariables: any;

  constructor(@Inject(ApiService) private api: ApiService) 
  { 
    
  }

  getPublicPlatformVariables(){
    console.log("Run getPublicPlatformVariables");
    return new Promise((resolve, reject) => { 
      this.api.getRequest('/platform/variables')
          .subscribe((response: any) => {

              this.publicPlatformVariables = response.payload;
              
              resolve(true);
          }, reject);
    });

  } 


  isNetlicensingEnable(){
      console.log("Run isNetlicensingEnable");
      let variable = this.getPlatformVariable(this.publicPlatformVariables, "NETLICENSING_ENABLED");
      if(variable!=null)
      {
        console.log(variable);
        if(variable["valueBoolean"]==true)
        {
          console.log("isNetlicensingEnable");
          return true;   
        }
        else{
          console.log("NOT isNetlicensingEnable");
          return false;
        }
      }
  }


  getPlatformVariable(variablesList, variableKey)
  {
    if(variablesList!=null && variablesList.length>0)
    {
      for (let index = 0; index < variablesList.length; index++) {
        if(variableKey == this.publicPlatformVariables[index]["keyField"])
        {
          return this.publicPlatformVariables[index];
          break;
        }
      }    
    }
  } 

}
