import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { AdminEditComponent } from '../admin-edit/admin-edit.component';
import { NotificationService } from 'app/main/services/notification.service';
import { AdminMessageComponent } from '../admin-message/admin-message.component';
import { Injector } from '@angular/core';
import { LoaderService } from 'app/main/shared/loader.service';
import { PushNotificationService } from '../services/pushnotification.service';
import { FormBuilder, Validators } from '@angular/forms';
import { EliminazioneComponent } from 'app/main/shared/eliminazione/eliminazione.component';
import { DynamicFormComponent } from 'app/main/shared/dynamic-form/dynamic-form.component';
import { DynamicFormDataInput } from 'app/main/shared/dynamic-form/dynamic-form-data-input';
import { PagedDataSource } from 'app/main/shared/iterfaces/paged-data-source';
import { fromEvent, merge, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { NavigationService } from 'app/main/services/navigation.service';
import { AccountService } from 'app/main/shared/services/account.service';
import { Router } from '@angular/router';
import { runInThisContext } from 'vm';

declare let moment: any;

@Component({
  selector: 'app-admin-util',
  templateUrl: './admin-util.component.html',
  styleUrls: ['./admin-util.component.scss']
})
export class AdminUtilComponent implements AfterViewInit {
  loader: LoaderService;
  pushNotificationService: any;
  public __formBuilder: any;
  loadPagedData: () => void;
  // searchInput: ElementRef;
  loaderSubscription: Subscription;
  showToggleOrderBlock: boolean;
  isLoaderShowing: boolean = false;
  private __navigationService: NavigationService;
  private __accountService: AccountService;
  private __router: Router;
  isExporting: boolean;
  loadDataSubscription: Subscription;
  dialogRef = null;
  setLoadDataSubscription(sub: Subscription) {
    if(this.loadDataSubscription) {
      this.loadDataSubscription.unsubscribe();
    }
    this.loadDataSubscription = sub;
    return this.loadDataSubscription;
  }

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private injector: Injector
  ) {
    this.loader = this.injector.get(LoaderService);
    this.pushNotificationService = this.injector.get(PushNotificationService);
    this.__formBuilder = this.injector.get(FormBuilder);
    this.__navigationService = this.injector.get(NavigationService);
    this.__accountService = this.injector.get(AccountService);
    this.__router = this.injector.get(Router);
    this.dialogRef = this.injector.get(MatDialog, null);
  }



  // Data variables
  data: Array<any> = [];



  @ViewChild(MatSort, /* TODO: add static flag */ {}) sort: MatSort;
  @ViewChild(MatPaginator, /* TODO: add static flag */ {}) paginator: MatPaginator;

  @ViewChild('searchInput', /* TODO: add static flag */ {}) searchInput: ElementRef;
  @ViewChild('dataTableElement', /* TODO: add static flag */ {}) dataTableElement: ElementRef;
  filterValue: string;

  // dataSource: MatTableDataSource<any> | PagedDataSource<any> = new MatTableDataSource<any>();
  dataSource: MatTableDataSource<any> | PagedDataSource<any> = new MatTableDataSource([]);
  dataSources: Array<any> = [];
  loading: boolean;
  dataCounter: number;



  ngAfterViewInit() {
    this.initDataSourcePaginators();
    this.showToggleOrderBlock = false;
  }

  initDataSourcePaginators() {
    if (this.dataSource instanceof MatTableDataSource) {
      if (this.sort && this.dataSource.data.length > 0) {
        this.dataSource.sort = this.sort;
      }
      if (this.paginator && this.dataSource.data.length > 0) {
        this.dataSource.paginator = this.paginator;
      }
    } else if (this.dataSource instanceof PagedDataSource) {
      //console.log(this.dataSource.data);
      //debugger;
      if (this.searchInput) {
        fromEvent(this.searchInput.nativeElement, 'keyup')
          .pipe(
            debounceTime(150),
            distinctUntilChanged(),
            tap(() => {
              if (!this.filterValue || this.filterValue.length > 2) {
                this.paginator.pageIndex = 0;
                this.loadPagedData();
              }
            })
          )
          .subscribe();
        fromEvent(this.searchInput.nativeElement, 'keydown')
          .pipe(
            debounceTime(150),
            distinctUntilChanged(),
            tap((event: any) => {
              var key = event.keyCode || event.charCode;

              if (!this.filterValue || ((key == 8 || key == 46) && this.filterValue.length == 0)) {
                this.paginator.pageIndex = 0;
                this.loadPagedData();
              }
            })
          )
          .subscribe();
      }

      if (this.sort && this.paginator) {
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        merge(this.sort.sortChange, this.paginator.page).pipe(
          tap(() => this.loadPagedData())
        ).subscribe();
      }
    }
  }

  ngOnDestroy() {
    if (this.loaderSubscription)
      this.loaderSubscription.unsubscribe();
    if(this.isLoaderShowing)
      this.hideLoader();
  }


  // New edit or create dialog
   /**
    * @deprecated Preferire dove possibile newDynamicDialog
    * @param data 
    * @param afterClosedCallback 
    * @param changePasswordCallback 
    * @param changeUsernameCallback 
    * @param sendingPushNotificationCallback 
    */
  newDialog(data: any,
    afterClosedCallback: any,
    changePasswordCallback?: any,
    changeUsernameCallback?: any,
    sendingPushNotificationCallback?: any) {
    const dialog = this.dialog.open(AdminEditComponent, {
      width: '400px',
      panelClass: 'custom-panel-class',
      data: data,
    });

    if (changePasswordCallback) {
      dialog.componentInstance.onChangePassword.subscribe(() => {
        changePasswordCallback();
      });
    }

    if (changeUsernameCallback) {
      dialog.componentInstance.onChangeUsername.subscribe(() => {
        changeUsernameCallback();
      });
    }

    if (sendingPushNotificationCallback) {
      dialog.componentInstance.onSendingPushNotification.subscribe(() => {
        sendingPushNotificationCallback(dialog);
      });
    }

    dialog.afterClosed().subscribe((datas: any) => {
      if (datas && afterClosedCallback) {
        afterClosedCallback(datas);
      }
    });
    return dialog;
  }

  // New edit or create dialog
  newDynamicDialog(data: DynamicFormDataInput,
    afterClosedCallback: any,
    changePasswordCallback?: any,
    changeUsernameCallback?: any,
    sendingPushNotificationCallback?: any) {
      let disableClose = false;
      //debugger;
      if(data.disableClose)
        disableClose = data.disableClose;
    const dialog = this.dialog.open(DynamicFormComponent, {
      width: '1000px',
      panelClass: 'custom-panel-class-large',
      data: data,
      disableClose: disableClose
    });

    if (changePasswordCallback) {
      dialog.componentInstance.onChangePassword.subscribe(() => {
        changePasswordCallback();
      });
    }

    if (changeUsernameCallback) {
      dialog.componentInstance.onChangeUsername.subscribe(() => {
        changeUsernameCallback();
      });
    }

    if (sendingPushNotificationCallback) {
      dialog.componentInstance.onSendingPushNotification.subscribe(() => {
        sendingPushNotificationCallback(dialog);
      });
    }

    dialog.afterClosed().subscribe((datas: any) => {
      if (datas && afterClosedCallback) {
        afterClosedCallback(datas);
      }
      if(datas == undefined && afterClosedCallback){
        afterClosedCallback("closed");
      }
    });
    return dialog;
  }



  // Checks if there exists data
  checkData() {
    // if(this.dataSource instanceof MatTableDataSource) {
    //   if (this.data) {
    //     return !this.loading && (this.data.length === 0);
    //   } else {
    //     return false;
    //   }
    // } else {
    //   return !this.loading && (!this.dataCounter || this.dataCounter === 0);
    // }

    if (this.data) {
      return !this.loading && (this.dataCounter === 0);
    } else {
      return false;
    }

  }

  retrieveRolesOptionList(): { value: string | number | boolean; name: string; setDisabled?: boolean; }[] {
    let roles = [
      { value: 'USER', name: 'CLIENT'},
      { value: 'MACHINE', name: 'MACCHINA'},
      { value: 'AMMINISTRATORE', name: 'ADMIN' },
      { value: 'OPERATORE', name: 'SPECIALISTA' },
      { value: 'TENANT_ADMINISTRATOR', name: 'AMMINISTRATORE AZIENDALE' },
    ];
    return roles;
  }


  // New delete dialog
  newElimina(data: any, callback: any) {
    const dialog = this.dialog.open(EliminazioneComponent, {
      data: `${data}`,
    });
    dialog.afterClosed().subscribe((datas) => {
      if (datas && datas['data']) {
        callback(datas);
      } else {
        callback(null);
      }
    });
  }
  newEliminaAll(data: any, callback: any) {
    const dialog = this.dialog.open(EliminazioneComponent, {
      data: {
        newWay: true,
        title: 'Conferma Eliminazione di tutti gli elementi',
        body: 'Sei sicuro? L\'azione è irreversibile'
      }
    });
    dialog.afterClosed().subscribe((datas) => {
      if (datas && datas['data']) {
        callback(datas);
      } else {
        callback(null);
      }
    });
  }

  newPersonalizedConfirmation(title: string, body:string, callback: any) {
    const dialog = this.dialog.open(EliminazioneComponent, {
      // data: `${data}`,
      data: {
        newWay: true,
        title: title,
        body: body
      }

    });
    dialog.afterClosed().subscribe((datas) => {
      if (datas && datas['data']) {
        callback(datas);
      } else {
        callback(null);
      }
    });
  }

  newTotalPersonalizedConfirmation(title: string, body:string, cancelButtonText: string, confirmButtonText: string, callback: any) {
    const dialog = this.dialog.open(EliminazioneComponent, {
      // data: `${data}`,
      data: {
        newWay: true,
        title: title,
        body: body,
        cancelButtonText: cancelButtonText,
        continueButtonText: confirmButtonText
      }

    });
    dialog.afterClosed().subscribe((datas) => {
      if (datas && datas['data']) {
        callback(datas);
      } else {
        callback(null);
      }
    });
  }

  // New delete dialog
  newConfirmation(data: any, callback: any) {
    const dialog = this.dialog.open(EliminazioneComponent, {
      // data: `${data}`,
      data: {
        newWay: true,
        title: 'Conferma Operazione',
        body: 'Sei sicuro?'
      }

    });
    dialog.afterClosed().subscribe((datas) => {
      if (datas && datas['data']) {
        callback(datas);
      } else {
        callback(null);
      }
    });
  }

  // New delete dialog
  newExportConfirmation(data: any, callback: any) {
    const dialog = this.dialog.open(EliminazioneComponent, {
      // data: `${data}`,
      data: {
        newWay: true,
        title: 'Bilanciamento Dataset',
        body: 'Si sta per avviare l\'export.\n E\' necessario bilanciare il dataset?',
        cancelButtonText: 'Non confermo',
        continueButtonText: 'Confermo'
      }

    });
    dialog.afterClosed().subscribe((datas) => {
      if (datas && datas['data']) {
        callback(datas);
      } else {
        callback(null);
      }
    });
  }

   // New delete dialog
   inferenceResultConfirmation(data: any, callback: any) {
    const dialog = this.dialog.open(EliminazioneComponent, {
      // data: `${data}`,
      data: {
        newWay: true,
        title: 'Risultato inferenza',
        body: 'Vuoi proseguire con il test o visualizzare il dataset creato?',
        cancelButtonText: 'Continua test',
        continueButtonText: 'Vai al dataset'
      }

    });
    dialog.afterClosed().subscribe((datas) => {
      
      //vai a dataset 
      if (datas && datas['data']) {
        callback(datas);
      }
      //Continua test
      else {
        callback(null);
      }
    });
  }

  // New delete dialog
  newSystemConfirmation(data: any, callback: any) {
    const dialog = this.dialog.open(EliminazioneComponent, {
      // data: `${data}`,
      data: {
        newWay: true,
        title: 'Conferma Esito',
        body: 'Confermi il risultato del sistema di inferenza?',
        cancelButtonText: 'Non confermo',
        continueButtonText: 'Confermo'
      }

    });
    dialog.afterClosed().subscribe((datas) => {
      if (datas && datas['data']) {
        callback(datas);
      } else {
        callback(null);
      }
    });
  }


  doYouWantToContinue(data: any, callback: any) {
    const dialog = this.dialog.open(EliminazioneComponent, {
      // data: `${data}`,
      data: {
        newWay: true,
        title: 'Valutazione R&R',
        body: 'Sei sicuro di procedere con la valutazione dell R&R?',
        cancelButtonText: 'No',
        continueButtonText: 'Si'
      }

    });
    dialog.afterClosed().subscribe((datas) => {
      if (datas && datas['data']) {
        callback(datas);
      } else {
        callback(null);
      }
    });
  }

  closeAllEmergencyByUserId(data: any, callback: any) {

    // const dialog = this.dialog.open(EliminazioneSegnalazioneComponent, {
    //   // data: `${data}`,
    //   data: {
    //     newWay: true,
    //     title: 'Chiusura emergenza',
    //     body: 'Si procede alla chiusura dell\'emergenza utente'
    //   }

    // });

    // dialog.afterClosed().subscribe((datas) => {
    //   console.log(datas);
    //   if (datas && datas['data']) {
    //     callback(datas);
    //   } else {
    //     callback(null);
    //   }
    // });

    callback({ data: true });

  }
  async newDataSources(data: Array<any> = [], newData?: boolean){
    let newSource = new MatTableDataSource(data);
    if (this.sort && newSource.data.length > 0) {
      console.log("sort");
      newSource.sort = this.sort;
    }
    if (this.paginator && newSource.data.length > 0) {
      console.log("paginator");
      newSource.paginator = this.paginator;
    }
    this.initDataSourcePaginators();
    this.dataSources.push(newSource);
  }

  // Creates new data source for table and sets loading paginator and gets deleted banks
  async newDataSource(data: Array<any> = [], newData?: boolean) {
    /*this.banks = await data.map((res: any, index: any) => {
      if (res.deleted === false) {
        return res;
      }
    });*/
    this.dataSource = new MatTableDataSource(data);
    if (this.sort && this.dataSource.data.length > 0) {
      this.dataSource.sort = this.sort;
    }
    if (this.paginator && this.dataSource.data.length > 0) {
      this.dataSource.paginator = this.paginator;
    }
    if (newData) {
      this.data = data;
    }
    this.dataCounter = this.data.length;
    this.loading = false;
    this.initDataSourcePaginators();
  }

  async newPagedSource(datasource: PagedDataSource<any>, loadPagedData: () => void) {
    //debugger;
    this.loadPagedData = loadPagedData;
    this.dataSource = datasource;
    this.loading = false;

    this.loaderSubscription = this.dataSource.loadingSubject.subscribe((d) => {
      //this.loading = d;
      if (d == true) {
        this.showLoader();
      } else {
        this.hideLoader();
      }
    })

    this.initDataSourcePaginators();

  }


  // New snackbar
  newSnackbar(text: any, snackBarClass: any, duration?) {
    if (duration === undefined) {
      duration = 2000;
    }

    return this.snackBar.open(`${text}`, '', {
      duration: duration,
      panelClass: snackBarClass,
      horizontalPosition: 'center'
    });
  }

  toggleOrderBlock() {
    this.showToggleOrderBlock = !this.showToggleOrderBlock;
    if (this.dataTableElement) {
      const dataTableElement = this.dataTableElement['_elementRef'].nativeElement;
      if (this.showToggleOrderBlock) {
        // dataTableElement.getElementByTagName('thead')[0].classList.add('showMobile');
        dataTableElement.firstElementChild.classList.add('show-mobile');
      } else {
        // dataTableElement.getElementByTagName('thead')[0].classList.remove('showMobile');
        dataTableElement.firstElementChild.classList.remove('show-mobile');

      }
    }
  }

  setDataCounter(newCounter) {
    console.log('setDataCounter', newCounter)
    if (!newCounter)
      newCounter = 0;
    this.dataCounter = newCounter;
  }

  askForNote(_formBuilder, notificationService: NotificationService, emergencyId) {
    const myFormGroup = _formBuilder.group({
      note: [''],
    });



    const askForNoteCallback = (result) => {
      if (result && result.data) {
        const note = result.data.note;
        console.log(note);
        if (note === undefined || note == null || note.trim() === '') {
          return;
        }
        this.loading = true;
        /*return notificationService.addNoteEmergency(emergencyId, note).subscribe((res) => {
          this.loading = false;
          this.newSnackbar(`Nota aggiunta.`, 'warning');
        }, (error) => {
          this.loading = false;
          console.error(error);
        });*/
      }
    };

    const obj = {
      topHeader: 'Inserisci una nota',
      header: 'Inserisci una nota per l\'emergenza',
      button: 'Inserisci',
      changePassword: false,
      textareas: [
        { placeholder: 'Nota', icon: '', formControlName: 'note' },
      ],
      selects: null,
      formGroup: myFormGroup,
    };
    this.newDialog(obj, askForNoteCallback);
  }



  askForNotes(_formBuilder, notificationService: NotificationService, emergencyId, noteId?, notes?): Promise<any> {

    return new Promise((resolve, reject) => {

      if (notes === undefined) {
        notes = '';
      }

      const myFormGroup = _formBuilder.group({
        note: [notes],
      });

      const askForNoteCallback = (result) => {
        if (result && result.data) {
          const note = result.data.note;
          console.log(note);
          if (note === undefined || note == null || note.trim() === '') {
            return;
          }
          this.loading = true;

          /*if (noteId === undefined) {
            return notificationService.createUserEmergencyNotes({ userEmergencyId: emergencyId, notes: note })
              .subscribe((res) => {
                this.loading = false;
                // this.newSnackbar(`Nota aggiunta.`, 'success');
                resolve(res['payload']);
              }, (error) => {
                this.loading = false;
                console.error(error);
                reject({ error: error });
              })
          }
          else {
            return notificationService.updateUserEmergencyNotes({ userEmergencyId: emergencyId, notes: note, id: noteId })
              .subscribe((res) => {
                this.loading = false;
                // this.newSnackbar(`Nota aggiunta.`, 'success');
                resolve(note);
              }, (error) => {
                this.loading = false;
                console.error(error);
                reject(error);
              });
          }*/
        }
      };

      const obj = {
        topHeader: 'Inserisci una nota',
        header: 'Inserisci una nota per l\'emergenza',
        button: 'Inserisci',
        changePassword: false,
        textareas: [
          { placeholder: 'Nota', icon: '', formControlName: 'note' },
        ],
        selects: null,
        formGroup: myFormGroup,
      };
      this.newDialog(obj, askForNoteCallback);


    });  // endof promise
  }

  newMessage(data: { title: string; subtitle?: string; body: any; }, callback: any) {
    const dialog = this.dialog.open(AdminMessageComponent, {
      // data: `${data}`,
      width: '400px',
      panelClass: 'custom-panel-class',
      data: data

    });
    dialog.afterClosed().subscribe((datas) => {
      if (datas && datas['data']) {
        if (callback) {
          callback(datas);
        }
      } else {
        if (callback) {
          callback(null);
        }
      }
    });
  }

  sortData(item: any, property: string, sortInfo: any): string | number {
    if (sortInfo.type == 'date') {
      const dateMomentObject = moment(item[property], sortInfo.format); // 1st argument - string, 2nd argument - format
      const dateObject = dateMomentObject.toDate();
      return dateObject.getTime();
    }
  }

  showLoader() {
    if(this.isLoaderShowing == false){
      this.isLoaderShowing = true;
      this.loader.show();
    }
  } 

  hideLoader() {
    //console.log("hiding");
    if(this.isLoaderShowing == true){
      this.isLoaderShowing = false;
      this.loader.hide();
    }
  }

  openForPushNotification(i, element) {
    // const myFormGroup = this._formBuilder.group({
    //   pushNotificationMsg: ['', Validators.required],
    // });

    const pushNotificationFormGroup = this.__formBuilder.group({
      pushNotificationMsg: ['', Validators.required]
    });

    // Opens new dialog to change username
    const sendPushNotification = (dialog) => {
      const newPushNotificationMsg = {
        newPushNotificationMsg: pushNotificationFormGroup.controls.pushNotificationMsg.value,
        contactId: element['userId']
      };

      this.loading = true;
      this.pushNotificationService.sendGenericNotification(newPushNotificationMsg).subscribe((res: any) => {
        dialog.close();
        this.loading = false;
        this.snackBar.open('Notifica inviata', '', {
          duration: 3000,
          panelClass: 'success',
        });
      }, (error) => {
        this.loading = false;
        // console.error(error);
        // this.snackBarMat.open("Errore nell'invio della notifica", '', {
        //   duration: 3000,
        //   panelClass: 'warn',
        // });
      });
    };

    this.newDialog({
      topHeader: 'Invia notifica all\'utente',
      button: 'Invia notifica',
      changePassword: false,
      textareas: [
        { placeholder: 'Notifica da inviare', icon: 'textsms', formControlName: 'pushNotificationMsg' },
      ],
      formGroup: pushNotificationFormGroup,
      data: element
    }, (data) => { }, null, null, sendPushNotification);

  }

  sendContactNotification(i, element) {
    this.pushNotificationService.sendNotificationForVideocall(element['userId']).subscribe((res: any) => {
      this.loading = false;
      this.snackBar.open('Notifica inviata', 'Attenzione l\'utente potrebbe essere online a breve', {
        duration: 3000,
        panelClass: 'success',
      });
    }, (error) => {
      this.loading = false;
      console.error(error);
      this.snackBar.open("Errore nell'invio della richiesta", '', {
        duration: 3000,
        panelClass: 'warn',
      });
    });
  }

  getCurrentNavigationElement() {
    let currElem = this.__navigationService.checkCurrent();
    //console.log('currentElement', currElem);
    return currElem;
  }

  checkAuthCurrentNavigationElement() {
    let currElem = this.getCurrentNavigationElement();
    console.log("currelem: "+currElem.bemethod);
    return this.__accountService.check(currElem);
  }

  checkAccessToPage() {
    if(!this.checkAuthCurrentNavigationElement()) {
      this.__router.navigateByUrl('/');
      this.newSnackbar(`Accesso negato`, 'warning');
      throw new Error(`Accesso negato`);
    }
  }

  removeElementFromArray(array, element) { 
    return array.filter(function(ele){ 
        return ele.id != element.id; 
    });
  }

  getElementIndexFromArray(array, element) { 
    return array.findIndex(ele => ele.id === element.id)
  }

}
