import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { of, throwError } from 'rxjs';
import { catchError, map, mergeMap, publishReplay, refCount } from 'rxjs/operators';
import { ErrorHandlerService } from './error-handler.service';
import { TokenService } from './token.service';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    private errorHandler: ErrorHandlerService,
    private sanitizer: DomSanitizer,
    private next: HttpHandler,
  ) {
    console.log('-----------ApiService Costructor!!!------------');
    if (environment.production || environment.local || environment.production_fatboy) {
      this.URL = '/aitrainer/api';
    }
    else { //qui è con proxy
      if(environment.isProxyDev)
        this.URL = '/aitrainer/api';  
      else if(environment.isProxyCol)
        this.URL = '/aitrainer/api';
      else if(environment.isProxyVpn)
        this.URL = '/localaitrainervpn/api';
      else
        this.URL = '/aitrainer/api';
    }
  }

  // URL: string = 'http://covidsafe.eclettica.net';
  // URL: string = 'http://192.168.0.183:8200';
  // Used from CORS backend issue
  // host = 'http://localhost:8200/';

  // LOCAL
  // URL = '/api';
  // REMOTE
  URL = '/aitrainer/api';

  blobData = {};

  getUrl() {
    return this.URL;
  }

  getWsSocketUrl() {
    if (environment.local)
      return 'ws://localhost:8200/aitrainer/ws';
    else if (environment.collaudo || environment.production_fatboy)
      return 'wss://aitrainer.maredigital.it/aitrainer/ws';
    else if (environment.isProxyCol)
      return 'wss://app.innoida.it/aitrainer/ws';
    else if (environment.isProdEcle)
      return 'wss://app.innoida.it/aitrainer/ws';
      else if (environment.isProxyVpn)
        return 'wss://app.innoida.it/aitrainer/ws';
    else
      return environment.webSocketUrl;
  }

  getStreamerUrl() {
    if(environment.collaudo || environment.isProxyDev)
      return 'https://stream.solveup.it/webrtcstreamer.html?video=';
    else if(environment.isProxyCol)
      return 'https://stream.solveup.it/webrtcstreamer.html?video=';
    else  
      return environment.streamerUrl;
}

  setUnauthHeaders() {
    // const token: string = '63c65b21-6bba-4eae-8456-9401d378675b';
    const headers: HttpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');
    return headers;
  }
  setHeaders() {
    const token: string = this.tokenService.getToken();
    // const token: string = '63c65b21-6bba-4eae-8456-9401d378675b';
    let headers: HttpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Basic YmFuY29tYXR1c2VyOk1hbmRhcmluby4k')
      .set('authtoken', `${token}`);
    return headers;
  }

  setFileHeaders() {
    const token: string = this.tokenService.getToken();
    // const token: string = '63c65b21-6bba-4eae-8456-9401d378675b';
    const headers: HttpHeaders = new HttpHeaders()
      .set('authtoken', `${token}`);
    return headers;
  }

  getRequest(endpoint: string, pars?) {
    const headers: any = this.setHeaders();
    if(!pars) {
      pars = {}; 
    }
    pars.headers = headers;
    // console.log('getRequest', pars)

    return this.http.get(`${this.URL}/${endpoint}`, pars).pipe(
      catchError((err: any) => {
        this.errorHandler.handleError(err);
        return throwError(err);
      })
    );
  }

  getTypedRequest<T>(endpoint: string, pars?) {
    const headers: any = this.setHeaders();
    if(!pars) {
      pars = {}; 
    }
    pars.headers = headers;
    // console.log('getRequest', pars)

    return this.http.get<T>(`${this.URL}/${endpoint}`, pars).pipe(
      catchError((err: any) => {
        this.errorHandler.handleError(err);
        return throwError(err);
      })
    );
    // return this.http.get(`${this.URL}/${endpoint}`, { headers: headers }).pipe(
    //   catchError((err: any) => {
    //     this.errorHandler.handleError(err);
    //     return throwError(err);
    //   })
    // );
  }

  getBlobRequest(endpoint: string) {
    const headers: any = this.setHeaders();
    if (!this.blobData[endpoint]) {
      this.blobData[endpoint] = this.http.get(`${this.URL}/${endpoint}`, { headers: headers, responseType: 'blob' }).pipe(
        catchError((err: any) => {
          this.errorHandler.handleError(err);
          return throwError(err);
        }),
        map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))),
        publishReplay(1),
        refCount()
      );
    }
    return this.blobData[endpoint];
  }

  getUnsecureBlobRequest(endpoint: string, manageError: boolean) {
    const headers: any = this.setHeaders();
    if (!this.blobData[endpoint]) {
      this.blobData[endpoint] = this.http.get(`${this.URL}/${endpoint}`, { headers: headers, responseType: 'blob' }).pipe(
        catchError((err: any) => {
          if(manageError)
            this.errorHandler.handleError(err);
          return throwError(err);
        }),
        // map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))),
        map(val => URL.createObjectURL(val)),
        publishReplay(1),
        refCount()
      );
    }
    return this.blobData[endpoint];
  }

  retrieveCompleteUrl(endpoint: string) {
    return `${this.URL}/${endpoint}`;
  }

  getBlobRequestUnMapped(endpoint: string, noCache: boolean) {
    let headers: HttpHeaders = this.setHeaders();
    if(noCache){
      headers = headers.set('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0');
      headers = headers.set('Pragma', 'no-cache');
      headers = headers.set('Expires', '0');
      //console.log(headers);
    }
    return this.http.get(`${this.URL}/${endpoint}`, { headers: headers, responseType: 'blob' }).pipe(
      catchError((err: any) => {
        this.errorHandler.handleError(err);
        return throwError(err);
      }),
    );
  }

  getRequestMockup(endpoint: string) {
    return this.http.get(`${endpoint}`);
  }

  postRequest(endpoint: string, body: any) {
    const headers: any = this.setHeaders();
    return this.http.post(`${this.URL}/${endpoint}`, body, { headers: headers }).pipe(
      catchError((err: any) => {
        this.errorHandler.handleError(err);
        return throwError(err);
      })
    );
  }

  postUrlParamsRequest(endpoint: string, params: any) {
    const headers: any = this.setHeaders();
    return this.http.post(`${this.URL}/${endpoint}/${params}`, {}, { headers: headers }).pipe(
      catchError((err: any) => {
        this.errorHandler.handleError(err);
        return throwError(err);
      })
    );
  }


  putUrlParamsRequest(endpoint: string, params: any) {
    const headers: any = this.setHeaders();
    return this.http.put(`${this.URL}/${endpoint}/${params}`, {}, { headers: headers }).pipe(
      catchError((err: any) => {
        this.errorHandler.handleError(err);
        return throwError(err);
      })
    );
  }

  postFileRequest(endpoint: string, body: any) {
    const headers: any = this.setFileHeaders();
    return this.http.post(`${this.URL}/${endpoint}`, body, { headers: headers }).pipe(
      catchError((err: any) => {
        this.errorHandler.handleError(err);
        return throwError(err);
      })
    );
  }


  postFileRepositoryRequest(endpoint: string, body: any) {
    const headers: any = this.setFileHeaders();
    return this.http.post(`${this.URL}/${endpoint}`, body, 
    { 
      headers: headers,
      reportProgress: true,  
      observe: 'events'
    }).pipe(
      catchError((err: any) => {
        this.errorHandler.handleError(err);
        return throwError(err);
      })
    );
  }

  putRequest(endpoint: string, body: any) {
    const headers: any = this.setFileHeaders();
    return this.http.put(`${this.URL}/${endpoint}`, body, { headers: headers }).pipe(
      catchError((err: any) => {
        this.errorHandler.handleError(err);
        return throwError(err);
      })
    );
  }

  deleteRequest(endpoint: string) {
    const headers: any = this.setHeaders();
    return this.http.delete(`${this.URL}/${endpoint}`, { headers: headers }).pipe(
      catchError((err: any) => {
        this.errorHandler.handleError(err);
        return throwError(err);
      })
    );
  }

}
