import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ApiService } from 'app/main/shared/services/api.service';
@Component({
  selector: 'app-admin-edit',
  templateUrl: './admin-edit.component.html',
  styleUrls: ['./admin-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminEditComponent implements OnInit, AfterViewInit {
  urlToUpload: any;
  uploadFunction: any;
  modals: any;

  constructor(
    public dialogRef: MatDialogRef<AdminEditComponent>,
    @Inject(MAT_DIALOG_DATA) public datas: any,
    private api: ApiService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef
  ) { }

  // Form variable
  formGroup: FormGroup;

  // Banks for ABI
  relatedBanks: Array<any> = [];
  banks: Array<any> = [];

  // File uploading variables
  formData: FormData = new FormData();
  logoId = '';
  fileLoading: boolean;

  // On change password emitter
  onChangePassword = new EventEmitter();

  onChangeUsername = new EventEmitter();

  onSendingPushNotification = new EventEmitter();

  ngOnInit() {

    // Checks where dialog is opened from 
    if (this.datas) {
      this.formGroup = this.datas.formGroup;
    }

    if (this.datas.urlToUpload) {
      this.urlToUpload = this.datas.urlToUpload;
    }

    if(this.datas.uploadFunction) {
      this.uploadFunction = this.datas.uploadFunction;
    }

    if(this.datas.modals) {
      this.modals = this.datas.modals;
    }

    console.log('admin-edit-component');

  }

  ngAfterViewInit() {

    // // Checks for data and sets to input 
    // if (this.datas && this.datas.data) {
    //   if (this.datas.data.name) {
    //     this.formGroup.controls.name.setValue(this.datas.data.name);
    //   }
    //   if (this.datas.data.ruolo) {
    //     this.formGroup.controls.role.setValue(this.datas.data.ruolo);
    //   }
    //   if (this.datas.data.surname) {
    //     this.formGroup.controls.surname.setValue(this.datas.data.surname);
    //   }
    //   if (this.datas.data.abi) {
    //     this.formGroup.controls.abi.setValue(this.datas.data.abi);
    //   }
    //   if (this.datas.data.tipo) {
    //     this.formGroup.controls.type.setValue(this.datas.data.tipo);
    //   }
    // }

  }


  // File uploading 
  documentInput(event) {

    if (this.uploadFunction != undefined && this.uploadFunction != null) {
      this.uploadFunction(event, this.formGroup);
    } else {

      const filesToUpload = <Array<File>>event.target.files;
      for (let i = 0; i < filesToUpload.length; i++) {
        this.formData.append('file', filesToUpload[i]);
      }

      if (this.urlToUpload == undefined || this.urlToUpload == null)
        this.urlToUpload = 'banca/logoUpload';

      this.api.postFileRequest(this.urlToUpload, this.formData)
        .subscribe((res: any) => {

          console.log(res);
          this.formGroup.controls.logo.setValue(res['payload']);
          this.newSnackbar('Logo caricato con successo', 'success');

        }, (err: any) => {

          console.log(err);

        }, () => {

        });
    }
  }

  // Sends data back
  async sendBank() {
    if (!this.formGroup.value['pushNotificationMsg']) {
      this.dialogRef.close({
        data: this.formGroup.value
      });
    } else {
      this.sendPushNotification();
    }

  }

  buttonClicked(bt, par) {
    let data = {};
      if (this.formGroup != undefined && this.formGroup != null) {
        data = {
          data: this.formGroup.value
        };
      }
    if (bt.action != undefined && bt.action != null) {
      try {
          bt.action(par);
      } catch (ex) {
        console.log(ex);
      }
    }
    let canClose = false
    if (bt.closeDialog != undefined && bt.closeDialog != null)
      canClose = bt.closeDialog;
    if (canClose) {
      
      this.dialogRef.close(data);
    }
  }


  changePassword() {
    return this.onChangePassword.emit();
  }

  changeUsername() {
    return this.onChangeUsername.emit();
  }

  sendPushNotification() {
    return this.onSendingPushNotification.emit();
  }

  // New edit or create dialog
  newDialog(data: any, callback: any) {
    const dialog = this.dialog.open(AdminEditComponent, {
      width: '400px',
      panelClass: 'custom-panel-class',
      data: data,
    });
    dialog.afterClosed().subscribe((datas: any) => {
      if (datas) {
        callback(datas);
      }
    });
  }


  // Opens new snackbar
  newSnackbar(text: any, snackBarClass: any) {

    return this.snackBar.open(`${text}`, '', {
      duration: 2000,
      panelClass: snackBarClass,
    });

  }

  // Checks password
  passwordConfirming(c: AbstractControl): { invalid: boolean } {

    if (c.get('password').value !== c.get('confirmPassword').value) {
      return { invalid: true };
    }

  }

  getPswRequired(element) {
    if (element !== undefined && element != null && element.isRequired !== undefined && element.isRequired != null) {
      return element.isRequired;
    }
    else {
      // di default è sempre true se pre true per la Password a meno che non venga espliciato required=false;
      return true;
    }
  }

  openModal(modal) {
    const dialog = this.dialog.open(modal.controller, {
      width: '400px',
      panelClass: 'custom-panel-class',
      data: modal.data ? modal.data : {},
    });
    dialog.afterClosed().subscribe((datas: any) => {
      if (datas) {
        console.log(datas);
      }
      if(modal.afterClosedCallback != undefined && modal.afterClosedCallback != null)
        modal.afterClosedCallback(datas, this.formGroup, modal, this.ref)
    });
  }

}
