import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule, MatIconModule, MatPaginatorModule, MatSnackBarModule, MatTableModule, MatRadioModule, MatCheckboxModule, MatSlideToggleModule} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { AppComponent } from 'app/app.component';
import { fuseConfig } from 'app/fuse-config';
import { LayoutModule } from 'app/layout/layout.module';
import 'hammerjs';
import { AppGuard } from './main/shared/guards/app.guard';


import { NgxQRCodeModule } from 'ngx-qrcode2';
import { SharedModule } from './main/shared/shared.module';
import { HttpTokenInterceptor } from './http-token-interceptor';
import { TokenService } from './main/shared/services/token.service';


import { CookieLawModule } from 'angular2-cookie-law';
import { GoogleAnalyticsService } from './google-analytics.service';

const appRoutes: Routes = [
    
    {
        path: 'admin',
        loadChildren: './main/admin/admin.module#AdminModule',
        canActivate: [AppGuard]
    },
    {
        path: 'apps',
        loadChildren: './main/apps/apps.module#AppsModule',

    },
    // {
    //     path: '',
    //     redirectTo: '/admin/dashboard',
    //     pathMatch: 'full'
    // },
    {
        path: '',
        redirectTo: '/admin/landing-redirect',
        pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: './main/login/login.module#LoginModule'
    },
    {
        path: 'register',
        loadChildren: './main/registration/registration.module#RegistrationModule'
    },
    // {
    //     path: 'labelling',
    //     loadChildren: './main/labelling/labelling.module#LabellingModule'
    // },
    {
        path: 'license-price',
        loadChildren: './main/pricing-table/pricing-table.module#PricingTableModule',
    },
    {
        path: '**',
        redirectTo: '/admin/landing-redirect',
        pathMatch: 'full'
    },
    // {
    //     path: '**',
    //     redirectTo: '/admin/dashboard',
    //     pathMatch: 'full'
    // },
];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        NgxQRCodeModule,
        RouterModule.forRoot(appRoutes, { enableTracing: false, relativeLinkResolution: 'legacy' }),

        // Material
        MatButtonModule,
        MatIconModule,
        MatPaginatorModule,
        MatTableModule,
        MatSnackBarModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSlideToggleModule,


        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,

        // App modules
        LayoutModule,

        //Shared
        SharedModule,
        
        //Cookie
        CookieLawModule
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpTokenInterceptor,
            multi: true,
            deps: [TokenService]
          },
          GoogleAnalyticsService
    ]
})
export class AppModule {

}
