import { Injectable } from '@angular/core';
import { EventWebSocketService } from './event-web-socket.service';
import { TokenService } from './token.service';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  

  private socket: WebSocket;
  private socketInterval: NodeJS.Timeout;
  isActive: boolean;
  messagesQueue: Array<any>;

  constructor(
    private eventWebSocketService: EventWebSocketService, 
    private tokenService: TokenService,
    private auth: AuthService,
    private apiService: ApiService) {
      this.messagesQueue = [];
      this.initService();
     }

     checkService() {
         setTimeout(() => {
            console.log('websocket service check service');
            if(this.auth.loggedIn()){
                console.log("check open...");
                this.initService();
            } else {
                if (this.socket != undefined && this.socket != null && this.socket.readyState == this.socket.OPEN) {
                    // console.log('close socket!');
                    this.socket.close();
                }
            }
         }, 200);
        
      }

  public initService() {
    console.log('WebsocketService: initService');
    if (this.auth.loggedIn()) {
      this.createSocket();
    } else {
        setTimeout(() => {
            this.checkService();
        }, 2000);
    }
  }

  private createSocket() {
    //debugger;
    // console.log('WebsocketService: createSocket');
      if (this.socket != undefined && this.socket != null && 
        (this.socket.readyState == this.socket.OPEN || this.socket.readyState == this.socket.CONNECTING))
          return;
    // console.log('WebsocketService: createSocket creating...');
    this.socket = new WebSocket(this.apiService.getWsSocketUrl() + "?authtoken=" + this.tokenService.getToken());
    this.socket.onmessage = this.onMessageCallback();
    this.socket.onerror = this.errorHandler;
    this.socket.onopen = this.socketOpen();
    this.socket.onclose = this.onClose.bind(this);
  }

  public closeSocket() {
    // console.log('chat service: close socket!');
    this.stopSocketHeartbit();
    if (this.socket != undefined && this.socket != null) {
        this.socket.close();
        this.socket = null;
    }
    this.isActive = false;
}

private socketOpen(): (string) => void {
    return (message) => {
        console.log('connect');
        this.isActive = true;
        this.socketInterval = setInterval(() => {
            this.emit('heartbit', { timestamp: new Date().getTime() }, 'heartbit');
        }, 20 * 1000);
    };
}

private stopSocketHeartbit() {
    if (this.socketInterval != undefined && this.socketInterval != null) {
        try {
            clearInterval(this.socketInterval);
            this.socketInterval = null;
        } catch (ex) {
            console.log(ex);
            this.socketInterval = null;
        }
    }
}

private onMessageCallback(): (string) => void {
  return (event) => {
      try {
          // console.log(event.data);
          var msg;
          try {
              msg = JSON.parse(event.data);
          } catch (e1) {
              msg = event.data;
          }
          // console.log(msg);
          this.eventWebSocketService.publishData(msg);
          // switch (msg.event) {
          //     case "newmessage":
          //         this.newMessage(msg.data);
          //         break;
          //     case "tryToContact":
          //         if (msg.data.result != undefined && msg.data.result != null && msg.data.result.textMessage != undefined && msg.data.result.textMessage != null && msg.data.result.textMessage == "true") {
          //             this.getChat(msg.data.result.fromUser.uuid);
          //         } else {
          //             this.eventWebSocketService.publishData({ event: 'redirectToEmergency' });
          //         }
          //         break;
          //     case "closeemergency":
          //         console.log('closeemergency');
          //         this.eventWebSocketService.publishData({ event: 'closeemergency', inChat: false });
          //         //this.forceCloseEmergency();
          // }
      } catch (e) {
          console.log("Errore getSignalMessageCallback - ", e);
      }
  };
}

public emit(status, data, event?, callback?) {
  //  debugger;
  if (event == undefined || event == null)
      event = 'call';
  data['status'] = status;
  var msg = {
      event: event,
      data: data
  }
  if (this.socket != undefined && this.socket != null && this.socket.readyState == this.socket.OPEN) {
      if(this.messagesQueue.length>0) {
          this.sendAllMessages();
      }
      this.socket.send(JSON.stringify(msg));
  } else if(this.socket != undefined && this.socket != null && this.socket.readyState == this.socket.CONNECTING){
      this.messagesQueue.push(JSON.stringify(msg));
  } else {
      console.log("Messaggio non inviato");
      console.log("Socket", this.socket);
      console.log("Message", msg);
  } 
}

private sendAllMessages() {
  for (let index = 0; index < this.messagesQueue.length; index++) {
      const element = this.messagesQueue[index];
      this.socket.send(element);
  }
  this.messagesQueue = [];
}

private errorHandler(error) {
  console.log("errorHandler", error);
}

private onClose(ev) {
  console.log('websocket onclose', ev);
  this.socket = null;
  this.checkService();
}

}
