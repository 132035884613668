import { Component, Inject, Injector, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { AccountService } from 'app/main/shared/services/account.service';
import { ApiService } from 'app/main/shared/services/api.service';
import { AdminUtilComponent } from '../../admin/admin-util/admin-util.component';

@Component({
  selector: 'app-table-modal',
  templateUrl: './table-modal.component.html',
  styleUrls: ['./table-modal.component.scss']
})
export class TableModalComponent extends AdminUtilComponent implements OnInit {
  
  // Table variables
  displayedColumns: Array<string> = [];
  pageSizeOptions: Array<number> = [10, 20, 30,50,100];
  pageSize = 100;
  dataList: Array<any> = [];
  columnList: Array<string> = [];

  constructor(
    private api: ApiService,
    private dialogMat: MatDialog,
    private snackBarMat: MatSnackBar,
    private account: AccountService,
    private _formBuilder: FormBuilder,
    private router: Router, 
    injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      super(dialogMat, snackBarMat, injector);
      this.dataList = data.datas;
      this.displayedColumns = data.columns; 
  }

  ngOnInit() {
    //console.log(this.dataList);
    this.newDataSource(this.dataList, true).then(() => {
      //this.sorting();
      //console.log(this.dataSource);
    });
  }

  async applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
