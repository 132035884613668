import { Component, Inject, OnInit, Injector } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { AdminUtilComponent } from 'app/main/admin/admin-util/admin-util.component';
import { UserService } from 'app/main/admin/services/user.service';
import { AppConstants } from 'app/main/utils/appConstants';
import { ImageCropComponent } from '../image-crop/image-crop.component';
import { AccountService } from '../services/account.service';
import { ProfileService } from '../services/profile.service';

// To modify
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends AdminUtilComponent implements OnInit {
  myProfile: any;
  data: any;
  user: any;
  relatedBanks: Array<any> = [];
  fileUrl;

  constructor(
    public dialogRef: MatDialogRef<ProfileComponent>,
    private dialogMat: MatDialog,
    private snackBarMat: MatSnackBar,
    private _formBuilder: FormBuilder,
    private userService: UserService,
    private accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) public datas: any,
    private profileService: ProfileService
  , injector: Injector) {
    super(dialogMat, snackBarMat, injector);

    this.myProfile = null;
  }

  getImageUrl(id, imageId) {
    return this.userService.renderUserProfileImage(id, imageId);
  }

  async ngOnInit() {

    this.profileService.data.subscribe((data) => {
      if (data && data.profileImageId) {
        this.data.profileImageId = data.profileImageId;
        console.log('data from profileService:', data);
        this.myProfile = this.userService.renderUserProfileImage(data.id, data.profileImageId)
          .subscribe((data) => {
            console.log('res: ', data);
            this.fileUrl = data;
          });
      }
    })

    // Recevies data from for profile
    await this.datas['data'].then((res) => {
      this.data = res;
     });

    this.user = await this.accountService.getAccount();
    // this.myProfile = await this.getImageUrl(this.user.id, this.user.profileImageId);

    // this.profileService.updatedProfile(this.data);
    if (this.user.profileImageId) {
      this.myProfile = this.userService.renderUserProfileImage(this.user.id, this.user.profileImageId)
        .subscribe((data) => {
          console.log('res: ', data);
          if (data)
            this.fileUrl = data;
        });
    }


    // await this.datas['banks'].subscribe(async (res) => {
    //   this.banca = res['payload'].find((resp: any) => {
    //     return resp.id === this.data.bancaId;
    //   })
    //   if (this.banca['relatedBanks']) {
    //     this.relatedBanks = this.banca['relatedBanks'].filter(e => this.banca['relatedBanks'].indexOf(e.name) !== -1);
    //   }
    // });
  }

  changePassword() {

    const passwordFormGroup = this._formBuilder.group({
      // password: ['', Validators.required],
      password: ['',Validators.compose([
        Validators.required,
        Validators.pattern(AppConstants.pwdPattern)
        ])],
      confirmPassword: ['', Validators.required],
      oldPassword: ['', Validators.required]
    }, { validator: this.passwordConfirming });

    // Opens new dialog to change password

    const passwordChange = () => {
      const resetPasswordData = {
        nuovaPassword: passwordFormGroup.controls.password.value,
        password: passwordFormGroup.controls.oldPassword.value
      };
      this.userService.changeMyPassword(resetPasswordData).subscribe((res: any) => {
        this.snackBarMat.open(res.infos.mainMessage, '', {
          duration: 3000,
          panelClass: 'success',
        });
      },
        error => console.log(error));

      // myFormGroup.controls.password.setValue(passwordFormGroup.controls.password.value);
    };

    this.newDialog({
      topHeader: 'Cambia la Password',
      button: 'Cambia la Password',
      passwords: [
        { placeholder: 'Password attuale', formControlName: 'oldPassword', type: 'password' },
        { placeholder: 'Nuova Password', formControlName: 'password', type: 'password' },
        { placeholder: 'Conferma password', formControlName: 'confirmPassword', type: 'password' },
      ],
      formGroup: passwordFormGroup,
    }, passwordChange);


  }


  // Changes image
  changeImage() {
    const dialog = this.dialogMat.open(ImageCropComponent, {
      width: '400px',
      panelClass: 'custom-panel-class',
    });

    dialog.afterClosed().subscribe(
      async data => {
        console.log("Dialog output:", data);
        if (data.error == false) {
          setTimeout(() => {                            
            this.profileService.updatedProfile(data);
          }, 200);
          // this.profileService.updatedProfile(data);
          // this.myProfile = await this.getImageUrl(data.id, data.profileImageId);
          /* this.snackBarMat.open('Immagine Utente aggiornata con successo', '', {
            duration: 3000,
            panelClass: 'success',
          });
          */
        }
        else {
          console.log('data: ', data);
          this.snackBarMat.open('Errore aggiornamento immagine', '', {
            duration: 3000,
            panelClass: 'warn',
          });
        }
      }
    );

  }

  // Checks password
  passwordConfirming(c: AbstractControl): { invalid: boolean } {

    if (c.get('password').value !== c.get('confirmPassword').value) {
      c.get('confirmPassword').setErrors({ invalid: true });
      return { invalid: true };
    } else {
      c.get('confirmPassword').setErrors(null);
    }
  }


}
