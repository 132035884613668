export const environment = {
    production: false,
    production_fatboy:true,
    isProdEcle: false,
    precollaudo: false,
    collaudo: false,
    local: false,
    hmr: false,
    isProxyDev: false,
    isProxyCol: false,
    isProxyVpn: false,
    debugEnabled: false,
    webSocketUrl: 'wss://aitrainer.maredigital.it/aitrainer/ws',
    streamerUrl: 'https://stream.solveup.com/webrtcstreamer.html?video=',
    ICE_SERVERS: [
        { urls: ['turn:turn.solveup.com:3478'], credential: "RosTurn202", username: "turnros" },
        { urls: 'stun:stun.l.google.com:19302' }
      ]
};
