import { ErrorHandler, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
  checkContinousError = false;
  checkErroreTimeout: NodeJS.Timeout;

  constructor(
    private tokenService: TokenService,
    private router: Router,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) { }



  handleError(err: any) {
    if (this.checkAuthError(err)) {
      this.handleAuthError(err);
    } else {
      this.handleServerError(err);
    }
  }

  checkAuthError(err) {
    return (err.status === 401) || (err.status === 403);
  }

  handleAuthError(err) {
    

    let redirect = this.checkMustRedirect();

    if (err.status === 401) {
      this.tokenService.deleteToken();
      if(redirect)
        this.router.navigate(['/login']);
    } else {
      if(redirect)
        this.router.navigate(['/']);
    }
    if (err && err.error && err.error.errors) {
      this.newError(err.error.errors.mainMessage || err.error.errors.message);
    } else {
      // this.newError(err.message);
    }
  }

  checkMustRedirect(): boolean {
    // let routerStateSnapshot = this.router.routerState.snapshot;
    // let currentUrl = routerStateSnapshot.url;
    // if(currentUrl.startsWith('/')) {
    //   currentUrl.substring(1);
    // }

    let publicUrls = [];
    for (let index = 0; index < this.router.config.length; index++) {
      const element = this.router.config[index];
      if((element.canActivate && element.canActivate.length > 0) 
      || (element.canActivateChild && element.canActivateChild.length >0))
        continue;
      publicUrls.push('/'+element.path);
    }

    if(publicUrls.indexOf(this.router.url) == -1)
      return true;
    return false;

    // if(this.route.routeConfig.canActivate && this.route.routeConfig.canActivate.length > 0)
    //   return true;
    // return false;
  }

  handleServerError(err: any) {
    let mex = null;
    if (err.error) {
      mex = (err.error.message || err.error.mainMessage);
    }
    if (!mex && err.error.errors) {
      mex = err.error.errors.mainMessage;
    }
    this.newError(mex);
    return throwError(new Error(err));
  }

  newError(mex?) {

    // console.log("eroore di connesione" , mex);

    // SE MEX nono è è definito molto probabilmente è un errore di rete gateway timeout in questo caso 
    // evito che venga ripetutto in continuazione e viene mostrato ogni 15 secondi, mentre se il messaggio è presente vuol dire che 
    // il serer a risposto correttamente ad una operazione pertanto restituisce l'errore ogni volta che si presenta
    if ( mex == undefined || mex == null)
    {
    // HO INTRODOTTO QUESTO WORKAROUND PER EVIATRE CHE SUL POLLING DELLE RICHIESTE INVIASSE CONTINUAMENTE SEGLAZIONE DI ERRORE
    // NEL CASO DI BREVE CADUTO DI LINEA AD ESMEPIO é MOLTO FASTIDIOSO LA SEGNALAZIONE OGNI SECONDO
      if (!this.checkContinousError)
      {
          let msg = `Si è verificato un errore di rete o di connessione.`;
          if (mex) {
            msg = mex;
          }

          this.checkContinousError = true;
          clearTimeout(this.checkErroreTimeout);
          this.checkErroreTimeout= null;

          return this.snackBar.open(msg, '', {
            duration: 3000,
            panelClass: 'warning',
          });
      }

      if(this.checkErroreTimeout == undefined || this.checkErroreTimeout == null)
      {
          this.checkErroreTimeout = setTimeout(() => {
            this.checkContinousError = false;
          }, 3000);
          
      }
    } else {
      return this.snackBar.open(mex, '', {
        duration: 3000,
        panelClass: 'warning',
      });
    }
  }
}
