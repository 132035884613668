import { FuseNavigation } from '@fuse/types';
import { QrCodeComponent } from 'app/main/shared/alert/qr-code/qr-code.component';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'APPLICATIONS',
        type: 'group',
        children: [
            {
                id: 'security',
                title: 'Security',
                type: 'collapsable',
                icon: 'help_outline',
                children: [
                    {
                        id: 'auths',
                        title: 'Autorizzazioni',
                        type: 'item',
                        // icon: 'help_outline',
                        url: '/admin/security/auths',
                        bemethod: 'MethodAuthService_createOrUpdate',
                        // canViewFunction: (account: any) => {
                        //     return true
                        // },
                    },
                    {
                        id: 'roles',
                        title: 'Ruoli',
                        type: 'item',
                        // icon: 'help_outline',
                        url: '/admin/security/roles',
                        bemethod: 'UserRoleService_createOrUpdate',
                        // canViewFunction: (account: any) => {
                        //     return true
                        // },
                    },
                    {
                        id: 'sessions',
                        title: 'Sessioni',
                        type: 'item',
                        // icon: 'help_outline',
                        url: '/admin/security/sessions',
                        bemethod: 'SessionTypeService_createOrUpdate',
                        // canViewFunction: (account: any) => {
                        //     return true
                        // },
                    },
                    {
                        id: 'chatconfig',
                        title: 'Configurazione Chat',
                        type: 'item',
                        // icon: 'help_outline',
                        url: '/admin/security/chat-config',
                        bemethod: 'ChatConfigService_updateChatConfig',
                    }, {
                        id: 'allusers',
                        title: 'Utenti',
                        type: 'item',
                        // icon: 'person_outline',
                        url: '/admin/security/users',
                        //bemethod: 'UserService_getUsersList',
                        canViewFunction: (account: any, auths, variables) => {
                            if (account && account.ruolo == 'AMMINISTRATORE')
                                return true;
                            return false;
                        },
                    }, {
                        id: 'export-config',
                        title: 'Esporta configurazione',
                        type: 'item',
                        // icon: 'person_outline',
                        // url: '/admin/security/users',
                        //bemethod: 'UserService_getUsersList',
                        canViewFunction: (account: any, auths, variables) => {
                            if (account && account.ruolo == 'AMMINISTRATORE')
                                return true;
                            return false;
                        }
                    }, {
                        id: 'import-config',
                        title: 'Importa configurazione',
                        type: 'item',
                        // icon: 'person_outline',
                        // url: '/admin/security/users',
                        //bemethod: 'UserService_getUsersList',
                        canViewFunction: (account: any, auths, variables) => {
                            if (true)
                                return false;
                            if (account && account.ruolo == 'AMMINISTRATORE')
                                return true;
                            return false;
                        }
                    }
                ]
            },
            {
                id: '11',
                title: 'Aziende',
                type: 'item',
                icon: 'how_to_reg',
                url: '/admin/aziende',
                //checkAdmin: true,
                bemethod: 'TenantService_findAllTenants'
            },
            // {
            //     id: '16',
            //     title: 'Dashboard',
            //     type: 'item',
            //     icon: 'insert_chart ',
            //     url: '/admin/dashboard',
            //     bemethod: 'BOGETPOSIZIONI'
            //     //checkOperator: true,
            // },
            /*{
                id: '1',
                title: 'Gestione Utenti',
                type: 'collapsable',
                icon: 'group',
                children: [
                    {
                        id: '11',
                        title: 'Aziende',
                        type: 'item',
                        icon: 'how_to_reg',
                        url: '/admin/aziende',
                        //checkAdmin: true,
                        bemethod: 'TenantService_findAllTenants'
                    },
                    {
                        id: 'machines',
                        title: 'Macchine',
                        type: 'item',
                        icon: 'input',
                        url: '/admin/machine',
                        bemethod: 'GETUSERSLIST'
                    },
                    {
                        id: '12',
                        title: 'Client',
                        type: 'item',
                        icon: 'group_work',
                        url: '/admin/client',

                        bemethod: 'GETUSERSLIST'
                    },
                    {
                        id: '13',
                        title: 'Operatori',
                        type: 'item',
                        icon: 'contacts',
                        url: '/admin/specialisti',
                        checkAdmin: true,
                        bemethod: 'GETOPERATORSLIST'
                    },
                    {
                        id: '14',
                        title: 'Admin',
                        type: 'item',
                        icon: 'supervised_user_circle',
                        url: '/admin/tenantadmins',
                        checkAdmin: true,
                        bemethod: 'GETOPERATORSLIST'
                    },
                    
                ]
            },*/
            {
                id: 'gestutenti',
                title: 'Gestione Utenti',
                type: 'collapsable',
                icon: 'group',
                children: [
                ]
            },
            {
                id: 'defClientInference',
                title: 'Gestione Clients',
                type: 'collapsable',
                icon: 'settings_overscan',
                children: [

                    {
                        id: 'defClientInference-type',
                        title: 'Tipi di nodi di inferenza',
                        type: 'item',
                        icon: 'done_outline',
                        url: '/admin/node-inference-type',
                        bemethod: 'NodeInferenceTypeService_createOrEditNodeInferenceTypeFromNodeInferenceTypeFacade',
                    },  
                    {
                        id: 'ainode',
                        title: 'Nodi di Training',
                        type: 'item',
                        icon: 'scatter_plot',
                        url: '/admin/ainodes',
                        bemethod: 'ArtificialIntelligenceNodeService_findAllArtificialIntelligenceNodes'
                    },         
                    {
                        id: 'infnode',
                        title: 'Nodi di Inferenza',
                        type: 'item',
                        icon: 'scatter_plot',
                        url: '/admin/node-inference',
                        bemethod: 'NodeInferenceService_findAllNodeInferencesWithoutDeleted'
                    },    
                    {
                        id: 'acqnode',
                        title: 'Nodi di Acquisizione',
                        type: 'item',
                        icon: 'scatter_plot',
                        url: '/admin/node-machine',
                        bemethod: 'NodeMachineService_findAllNodeMachinesWithoutDeleted'
                    },                                                    
                ]        
            },  
            {
                id: 'file-manager-1',
                title: 'File explorer',
                type: 'item',
                icon: 'folder',
                url: '/admin/file-manager',
                bemethod: 'GETREPOSITORY',
                /* id       : 'file-manager',
                title    : 'Repository',               
                type: 'collapsable',
                icon: 'folder',
                children: [
                    {
                        id: 'file-manager-1',
                        title: 'File explorer',
                        type: 'item',
                        icon: 'insert_drive_file',
                        url      : '/admin/file-manager',
                        bemethod: 'GETREPOSITORY',
                    },
                    {
                        id: 'file-manager-2',
                        title: 'Repository scan',
                        type: 'item',
                        icon: 'scanner',
                        url: '/admin/scanRepository',
                        bemethod: 'RepositoryService_ScanRepository'
                    } 
                ]*/
            },
            {
                id: '13',
                title: 'Dataset',
                type: 'item',
                icon: 'database',
                url: '/admin/datasets',
                bemethod: 'DatasetService_findAllDatasets'
            },
            {
                id: '133',
                title: 'Progetto',
                type: 'item',
                icon: 'dashboard',
                url: '/admin/projects',
                bemethod: 'ProjectService_findAllProjects'
            },

            {
                id: 'clientInference',
                title: 'Test Inference',
                type: 'collapsable',
                icon: 'settings_overscan',
                children: [

                    {
                        id: 'clientInference-clas',
                        title: 'Modello di Classificazione',
                        type: 'item',
                        icon: 'done_outline',
                        url: '/admin/classification',
                        bemethod: 'ProjectService_invokeSingleInferenceModel',
                    },
                    {
                        id: 'clientInferenc-det',
                        title: 'Modello di Detection',
                        type: 'item',
                        icon: 'done_outline',
                        url: '/admin/detection',
                        bemethod: 'ProjectService_invokeSingleInferenceModel'
                    },
                    {
                        id: 'clientInferenc-ad',
                        title: 'Modello Anomaly Detection',
                        type: 'item',
                        icon: 'done_outline',
                        url: '/admin/anomaly-detection',
                        bemethod: 'ProjectService_invokeSingleInferenceModel'
                    },
                    {
                        id: 'clientInferenc-seg',
                        title: 'Modello di Segmentation',
                        type: 'item',
                        icon: 'done_outline',
                        url: '/admin/segmentation',
                        bemethod: 'ProjectService_invokeSingleInferenceModel'
                    }
                    /* ,
                    {
                        id: 'clientInference-1',
                        title: 'Test onnx model',
                        type: 'item',
                        icon: 'done_outline',
                        url: '/admin/resnet',
                        bemethod: 'ProjectService_invokeSingleInferenceModel',
                    } */
                ]
            },

            {
                id: 'Codadielaborazione-1',
                title: 'Coda di elaborazione',
                type: 'item',
                icon: 'bookmark',
                url: '/admin/inferenceImagesList',
                bemethod: 'LineInferenceService_findAllInferenceImages'
            },

            {
                id: 'RANDR-1',
                title: 'RANDR',
                type: 'item',
                icon: 'details',
                url: '/admin/randr',
                bemethod: 'RAndRService_findAllRAndRObjects'
            },
            {
                id: '40',
                title: 'Camere IP',
                type: 'item',
                icon: 'videocam',
                // checkOperator: true,
                url: '/admin/camere',
                bemethod: 'CameraService_findAllCamerasByTenant'
            },
            {
                id: '19',
                title: 'Configurazioni',
                type: 'item',
                icon: 'settings_applications',
                // checkAdmin: true,
                url: '/admin/appConfig',
                bemethod: 'ConfigurationService_createOrUpdateAppConfigurationEntity',
                //children: [
                //     {
                //         id: '20',
                //         title: 'App',
                //         type: 'item',
                //         icon: 'settings_cell',
                //         url: '/admin/appConfig',
                //         bemethod: 'CREATEORUPDATEAPPCONFIGURATIONENTITY',
                //     }
                // ]
            },
            {
                id: '50',
                title: 'Licenze',
                type: 'item',
                icon: 'credit_card',
                url: '/admin/netlicence/home/UUID-TNNT-1',
                // checkAdmin: true,
                bemethod: 'LicenseService_buyProduct'
            },
            {
                id: '57',
                title: 'Acquisto Licenze',
                type: 'item',
                icon: 'credit_card',
                url: '/admin/buy-licenses',
                bemethod: 'LicenseService_buyProduct'
            },
            {
                id: '56',
                title: 'Lista Licenze',
                type: 'item',
                icon: 'featured_play_list',
                url: '/admin/history-licenses',
                bemethod: 'TenantService_getLicensesFromCurrentUser'
            },
        ]
    }
];
