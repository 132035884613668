import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';

import { filter, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    loaderEl: any;
    player: AnimationPlayer;

    /**
     * Constructor
     *
     * @param {AnimationBuilder} _animationBuilder
     * @param _document
     * @param {Router} _router
     */
    constructor(
        private _animationBuilder: AnimationBuilder,
        @Inject(DOCUMENT) private _document: any,
        private _router: Router
    ) {
        // Initialize
        this._init();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Initialize
     *
     * @private
     */
    private _init(): void {
        // Get the splash screen element
        this.loaderEl = this._document.body.querySelector('#app-loader-screen');
        setTimeout(() => {
            this.hide();
        }); 
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Show the splash screen
     */
    show(): void {
      //console.log('loader service show!')
        this.player =
            this._animationBuilder
                .build([
                    style({
                        opacity: '0',
                        background: 'rgba(0,0,0,0.8)',
                        zIndex: '9999999'
                    }),
                    animate('400ms ease', style({ opacity: '1' }))
                ]).create(this.loaderEl);

        setTimeout(() => {
            this.player.play();
        }, 0);
    }

    /**
     * Hide the splash screen
     */
    hide(): void {
      //console.log('loader service hide!')
        this.player =
            this._animationBuilder
                .build([
                    style({ opacity: '1' }),
                    animate('400ms ease', style({
                        opacity: '0',
                        zIndex: '-10'
                    }))
                ]).create(this.loaderEl);

        setTimeout(() => {
            this.player.play();
        }, 0);
    }
}
