import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { ApiService } from './api.service';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  

  constructor(
    private tokenService: TokenService,
    private api: ApiService,
    private account: AccountService
  ) { }

  loggedIn() {
    return !!this.tokenService.getToken();
  }

  refresh() {
    return this.api.getRequest('bo/refresh');
  }

  logOut() {
    return this.api.postRequest('bo/logout', {});
  }

  getAuthMap() {
    return this.api.getRequest('bo/getUserPermissions');
  }
  exportConfig() {
    return this.api.getRequest('security/exportConfig');
  }

}
