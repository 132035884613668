import { Injectable } from '@angular/core';

import { FileElement } from '../file-explorer/model/element';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { map, filter } from 'rxjs/operators';
import { ApiService } from 'app/main/shared/services/api.service';
import { HttpEventType } from '@angular/common/http';
import { UpdateModel } from '../file-explorer/model/update-model';

export interface IFileService {
  add(fileElement: FileElement);
  delete(id: string);
  update(id: string, update: Partial<FileElement>);
  queryInFolder(folderId: string): Observable<FileElement[]>;
  get(id: string): FileElement;
}

@Injectable()
export class FileService implements IFileService {
  private map = new Map<string, FileElement>();
  sortOrder: string = 'ASC';

  constructor(private api: ApiService) { }

  findAllRepos(): Observable<FileElement[]> {
    // Request as transfer object <ServerData>
    return this.getRepositories().pipe(
      map(res => {
        return res['payload'].map(item => {
          const fe = new FileElement();
          fe.id = item.key;
          fe.isFolder = true;
          fe.type = 'folder';
          fe.name = item.value ? item.value : item.fileName;
          fe.parent = 'root';
          fe.Uuid = null;
          fe.repoName = item.key;
          // fe.repositoryId = item.key ? item.key : item.repoName;
          return fe;
        })
      })
    );
  }

  getFolderByName(repoName, parentUuid, folderName): Observable<any> {

    const body = {
      parentUuid: parentUuid,
      repoName: repoName
    };

    return this.getRepository(body).pipe(
      map(res => {
        return res['payload']
        .map(item => { 
          console.log('item: ', item);
          return item ;
        })
        .filter(v => (v.fileName === folderName && v.fileType === 'FOLDER'))
      })

    );
  }


  getFolderFiles(repoName, Uuid): Observable<any[]> {
    // Request as transfer object <ServerData>
    const body = {
      parentUuid: Uuid,
      repoName: repoName
    };

    return this.getRepository(body).pipe(
      map(res => {
        return res['payload'];
      })
    );
  }


  findAllFiles(element: any): Observable<FileElement[]> {
    // Request as transfer object <ServerData>
    const body = {
      parentUuid: element.Uuid,
      repoName: element.repoName
    };

    return this.getRepository(body).pipe(
      map(res => {
        return res['payload'].map(item => {

          return this.InitFileElement(item, { id: element.id, repoName: element.repoName });

        })
      })
    );
  }


  InitFileElement(item, element): FileElement {
    const fe = new FileElement();

    if (item.fileType === 'FOLDER') {
      fe.type = "folder";
      fe.isFolder = true;
    }
    else {
      // check type with extension to show the right icon
      fe.type = 'document';
      fe.isFolder = false;
    }

    fe.id = item.repositoryId;
    fe.name = item.fileName;
    fe.Uuid = fe.id;
    fe.deleted = item.deleted;
    fe.canView = item.canView;
    fe.canModify = item.canModify;
    fe.tags = item.tags;
    fe.isRemovable = item.isRemovable;

    if (element) {
      fe.parent = element.id;
      fe.repoName = element.repoName;
    }
    //console.log(fe);
    return fe;
  }


  add(fileElement: FileElement) {
    let element = this.get(fileElement.id);

    // Add if not present
    if (element === undefined || element == null) {
      this.map.set(fileElement.id, this.clone(fileElement));
    }
    else {
      this.update(fileElement.id, fileElement);
    }

    return fileElement;
  }


  delete(id: string) {
    this.map.delete(id);
  }

  update(id: string, update: Partial<FileElement>) {
    
    let element = this.map.get(id);
    element = Object.assign(element, update);
    this.map.set(element.id, element);
    const body: UpdateModel = this.convertFileElementToUpdateModel(element);
    return this.updateInfo(body);

  }

  convertFileElementToUpdateModel(element: FileElement): UpdateModel {
    const body: UpdateModel = {
        repositoryId: element.id,
        canModify: element.canModify,
        canView: element.canView,
        tags: element.tags,
        fileName: element.name,
        repositoryName: element.repoName,
        parentId: element.parent == element.repoName ? null : element.parent
    }
    return body;
}

  private querySubject: BehaviorSubject<FileElement[]>;
  
  toggleSortOrder() {
    if(this.sortOrder == 'ASC')
      this.sortOrder = 'DESC';
    else
      this.sortOrder = 'ASC';
  }
  
  filesOrder(firstEl: FileElement, secondEl: FileElement): number {
    return (this.sortOrder == 'ASC' ? 1 : -1) * (firstEl.name.toUpperCase() < secondEl.name.toUpperCase() ? -1 : firstEl.name.toUpperCase() > secondEl.name.toUpperCase() ? 1 : 0)
  }
  
  queryInFolder(folderId: string) {
    const result: FileElement[] = [];
    this.map.forEach(element => {
      if (element.parent === folderId) {
        result.push(this.clone(element));
      }
    });
    result.sort(this.filesOrder.bind(this));
    if (!this.querySubject) {
      this.querySubject = new BehaviorSubject(result);
    } else {
      this.querySubject.next(result);
    }
    return this.querySubject.asObservable();
  }

  get(id: string) {
    return this.map.get(id);
  }

  clone(element: FileElement) {
    return JSON.parse(JSON.stringify(element));
  }

  getRepositories() {
    return this.api.getRequest(`bo/repository/getRepositories`);
  }

  shareFile(fileId, userId) {
    return this.api.postUrlParamsRequest(`bo/repository/shareFile`, `${fileId}/${userId}`).pipe(
      map(res => {
        let item = res['payload'];

        return this.InitFileElement(item, null);

      })
    );
  }

  getRepository(body) {
    return this.api.postRequest('bo/repository/getRepository', body);
  }

  getRepositoryDetail(id: any): Observable<Object>{
    return this.api.getRequest(`bo/repository/getFileDetail/${id}`);
  }

  deleteProject(id: any): Observable<Object> {
    return this.api.deleteRequest(`bo/projects/${id}`);
  }

  newFolder(body) {
    return this.api.postRequest('bo/repository/newFolder', body);
  }

  updateInfo(body): Observable<FileElement> {
    return this.api.postRequest(`bo/repository/updateInfo`, body).pipe(
      map(res => {
        let item = res['payload'];
        return this.InitFileElement(item, null);
      })
    );
  }

  getRepositoryByTag(tagId) {
    return this.api.getRequest(`bo/repository/getRepositoryByTag/${tagId}`);
  }
 

  deleteFolder(id): Observable<FileElement> {
    return this.api.deleteRequest(`bo/repository/updateInfo/${id}`).pipe(
      map(res => {
        let item = res['payload'];

        return this.InitFileElement(item, null);

      })
    );
  }

  addNewFolder(body: any): Observable<any> {

    return this.newFolder(body).pipe(
      map(res => {
        return res['payload'];
      })
    );
  }

  addFolder(body: any, currentFileElement: FileElement): Observable<FileElement> {

    return this.newFolder(body).pipe(
      map(res => {
        let item = res['payload'];
        let parent = body.parentId === null ? currentFileElement.id : body.parentId;

        return this.InitFileElement(item, { id: parent, repoName: body.repoName });

      })
    );
  }

  uploadFile(repoName: string, repoFolderId: string, file) {
    console.log("FILE", file);

    const formData = new FormData();
    formData.append('file', file.data);
    formData.append('repoName', repoName);
    formData.append('repoFolderId', repoFolderId);

    file.inProgress = true;
    this.api.postFileRepositoryRequest('bo/repository/', formData).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      })
    ).subscribe((event: any) => {
      // console.log('event from uploadFile:' , event);
      if (typeof (event) === 'object') {
        // console.log(event.body);  
      }
    });

  }


  uploadImage(repoName: string, repoFolderId: string, file) {

    console.log("FILE", file);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('repoName', repoName);
    formData.append('repoFolderId', repoFolderId);

    file.inProgress = true;
    this.api.postFileRepositoryRequest('bo/repository/uploadFile', formData).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      })
    ).subscribe((event: any) => {
      // console.log('event from uploadFile:' , event);
      if (typeof (event) === 'object') {
        // console.log(event.body);  
      }
    });

  }

  uploadImageObservable(repoName: string, repoFolderId: string, file) {

    console.log("FILE", file);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('repoName', repoName);
    formData.append('repoFolderId', repoFolderId);

    file.inProgress = true;
    return this.api.postFileRepositoryRequest('bo/repository/uploadFile', formData).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      })
    ) 
  }

  getFile(id) {
    return this.api.getBlobRequestUnMapped(`bo/repository/getFile/${id}`, true);
  }
  getScanRepository() {
    return this.api.getRequest(`bo/repository/getScanRepository/`);
  }
  scanRepository() {
    return this.api.postRequest('bo/repository/scanRepository','');
  }

  retrieveFileUrl(​id, token, withThumb: boolean) {
    const ​tokenAndRepoId = `${token}_-_${​id}`;
    const url = this.api.getUrl();
    const thumb = (withThumb) ? '?isThumb=true' : '';
    const endpoint = `bo/retrieveFile/${​tokenAndRepoId}${thumb}`;

    return `${url}/${endpoint}`
  }

  retrieveThumbUrl(​id, token) {
    return this.retrieveFileUrl(​id, token, true);
  }
  
}


