import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDialog } from '@angular/material';
import { ApiService } from '../services/api.service';
import { DynamicFormDataButtonElement, DynamicFormDataInput, DynamicFormDataInputElement, DynamicFormDataModalElement } from './dynamic-form-data-input';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicFormComponent implements OnInit {

  modals: any;
  clickedItem: DynamicFormDataInputElement;

  constructor(
    public dialogRef: MatDialogRef<DynamicFormComponent>,
    @Inject(MAT_DIALOG_DATA) public datas: DynamicFormDataInput,
    private api: ApiService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef) { }

    // Form variable
  formGroup: FormGroup;

  // File uploading variables
  formData: FormData = new FormData();
  logoId = '';
  fileLoading: boolean;

  // On change password emitter
  onChangePassword = new EventEmitter();

  onChangeUsername = new EventEmitter();

  onSendingPushNotification = new EventEmitter();
  
  ngOnInit() {
     // Checks where dialog is opened from 
     if (this.datas) {
      this.formGroup = this.datas.formGroup;
    }

    for (let index = 0; index < this.datas.elements.length; index++) {
      const element = this.datas.elements[index];
      if(element.visibleIf) {
        const controllerElement = this.formGroup.get(element.visibleIf.formControlName);
        controllerElement.valueChanges.subscribe(newvalue => {
          this.checkElement(newvalue, element);
       });
       this.checkElement(controllerElement.value, element);
      } else {
        element.isVisible = true;
      }
    }

    //console.log('dynamic-form-component');
  }

  checkElement(newvalue, element: DynamicFormDataInputElement) {
    const controlledElement = this.formGroup.get(element.formControlName);
          let tocheck = false;        
          for (let index = 0; index < element.visibleIf.values.length; index++) {
             const val = element.visibleIf.values[index];
             if(newvalue == val){
                tocheck = true;
                break;
             }
          }
          if(tocheck) {
            element.isVisible = true;
             if(element.required == true) {
              controlledElement.setValidators([Validators.required]);
             } else {
              controlledElement.setValidators(null);
             }
          } else {
            element.isVisible = false;
            controlledElement.setValidators(null);
          }
          controlledElement.updateValueAndValidity();
  }



  clickUploadButton(item: DynamicFormDataInputElement, i) {
    if(!item || !i)
      return;
    const el = document.getElementById('fileInput_' + i);
    if(!el)
      return;
    if(item.accept) {
      el.setAttribute('accept', item.accept);
    } else {
      el.removeAttribute('accept');
    }
    this.clickedItem = item;
    el.click();
  }

  /**
   * Function to upload file
   */

    // File uploading 
  documentInput(event: any) {
    if(!this.clickedItem)
      return;
    
    if (this.clickedItem.uploadFunction != undefined && this.clickedItem.uploadFunction != null) {
      this.clickedItem.uploadFunction(event, this.formGroup, this.clickedItem.formControlName, this.clickedItem, this.ref).then((value)=>{
        if(this.clickedItem.formControlName)
          this.formGroup.controls[this.clickedItem.formControlName].updateValueAndValidity();
          this.clickedItem = null;
      })
    } else {

      const filesToUpload = <Array<File>>event.target.files;
      for (let i = 0; i < filesToUpload.length; i++) {
        this.formData.append('file', filesToUpload[i]);
      }

      if (this.clickedItem.urlToUpload == undefined || this.clickedItem.urlToUpload == null) {
        // this.urlToUpload = 'banca/logoUpload';
        this.clickedItem = null;
        throw new Error("url not set! Impossible to upload");
      }

      this.api.postFileRequest(this.clickedItem.urlToUpload, this.formData)
        .subscribe((res: any) => {

          console.log(res);
          this.clickedItem.formControlName
          this.formGroup.controls[this.clickedItem.formControlName].setValue(res['payload']);
          let message = 'File caricato con successo';
          if(this.clickedItem.uploadMessage)
            message = this.clickedItem.uploadMessage;
          this.newSnackbar(message, 'success');
          this.clickedItem = null;
        }, (err: any) => {
          this.newSnackbar('Errore nel caricamento del file', 'success');
          console.log(err);
          this.clickedItem = null;
        }, () => {

        });
    }
  }

  // Sends data back
  async submitForm() {
    if (!this.formGroup.value['pushNotificationMsg']) {
      this.dialogRef.close({
        data: this.formGroup.value
      });
    } else {
      this.sendPushNotification();
    }

  }

  buttonClicked(bt: DynamicFormDataButtonElement, par) {
    let data = {};
      if (this.formGroup != undefined && this.formGroup != null) {
        data = {
          data: this.formGroup.value
        };
      }
    if (bt.action != undefined && bt.action != null) {
      try {
          bt.action(par);
      } catch (ex) {
        console.log(ex);
      }
    }
    let canClose = false
    if (bt.closeDialog != undefined && bt.closeDialog != null)
      canClose = bt.closeDialog;
    if (canClose) {
      
      this.dialogRef.close(data);
    }
  }

// Checks password
  passwordConfirming(c: AbstractControl): { invalid: boolean } {

    if (c.get('password').value !== c.get('confirmPassword').value) {
      return { invalid: true };
    }

  }

  getPswRequired(element) {
    if (element !== undefined && element != null && element.isRequired !== undefined && element.isRequired != null) {
      return element.isRequired;
    }
    else {
      // di default è sempre true se pre true per la Password a meno che non venga espliciato required=false;
      return true;
    }
  }
  
  /**
   * UTILS FUNCTIONS
   */

   // Opens new snackbar
  newSnackbar(text: any, snackBarClass: any) {

    return this.snackBar.open(`${text}`, '', {
      duration: 2000,
      panelClass: snackBarClass,
    });

  }

  

  openModal(modal: DynamicFormDataModalElement, formControlName: string) {
    const dialog = this.dialog.open(modal.controller, {
      width: '100%',
      panelClass: 'custom-panel-class',
      data: modal.data ? modal.data : {},
    });
    dialog.afterClosed().subscribe((datas: any) => {
      if (datas) {
        //console.log(datas);
      }
      if(modal.afterClosedCallback != undefined && modal.afterClosedCallback != null)
        modal.afterClosedCallback(datas, this.formGroup, modal, this.ref, formControlName)
    });
  }

  sendPushNotification() {
    return this.onSendingPushNotification.emit();
  }

}
