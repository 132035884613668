import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDialog } from '@angular/material';
import { ApiService } from 'app/main/shared/services/api.service';
import { FormGroup, AbstractControl } from '@angular/forms';
import { AdminEditComponent } from '../admin-edit/admin-edit.component';

@Component({
  selector: 'app-admin-message',
  templateUrl: './admin-message.component.html',
  styleUrls: ['./admin-message.component.scss']
})
export class AdminMessageComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AdminMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public datas: any,
    private api: ApiService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) { }



 ngOnInit() {
  console.log(this.datas);
 }

 ngAfterViewInit() {

 }

 // New edit or create dialog
 newDialog(data: any, callback: any) {
   console.log(data);
   const dialog = this.dialog.open(AdminMessageComponent, {
     width: '400px',
     panelClass: 'custom-panel-class',
     data: data,
   });
   dialog.afterClosed().subscribe((datas: any) => {
     if (datas) {
       callback(datas);
     }
   });
 }


 // Opens new snackbar
 newSnackbar(text: any, snackBarClass: any) {

   return this.snackBar.open(`${text}`, '', {
     duration: 2000,
     panelClass: snackBarClass,
   });

 }

}
