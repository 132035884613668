import { Component, OnInit } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MatDialogRef } from '@angular/material';
import { UserService } from '../../admin/services/user.service';
import { AccountService } from 'app/main/shared/services/account.service';

// To modify
@Component({
  selector: 'app-image-crop',
  templateUrl: './image-crop.component.html',
  styleUrls: ['./image-crop.component.scss']
})
export class ImageCropComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ImageCropComponent>,
    private userService: UserService,
    private accountService: AccountService) { }

  imageChangedEvent: any = '';
  croppedImage: any = '';


  base64ToFile(base64Image: string): Blob {
    const split = base64Image.split(',');
    const type = split[0].replace('data:', '').replace(';base64', '');
    const byteString = atob(split[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  }


  // When you choose a file from the file input, it will trigger fileChangeEvent.
  // That event is then passed to the image cropper through imageChangedEvent which will load the image into the cropper
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  // Everytime you release the mouse, 
  // the imageCropped event will be triggerd with the cropped image as a Base64 string in its payload.
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  onSave() {

    // Convert it to a blob to upload
    let blob = this.base64ToFile(this.croppedImage);

    this.userService.uploadImage(blob).subscribe(async (res: any) => {
      const imageId = res.payload.fileId
      console.log('imageId: ', res.payload.fileId);

      // Gets account 
      await this.accountService.getAccount().then((res: any) => {
        console.log(res);
        res.profileImageTempId = imageId;

        this.userService.editOperatore(res).subscribe((resp) => {
          console.log(resp);
          this.accountService.setAccount(resp['payload']);
          this.dialogRef.close({ id: resp['payload'].id, profileImageId: resp['payload'].profileImageId, error: false });

        }, (error) => {
          console.log(error);
          this.dialogRef.close({ error: true });
        }
        );
      });
    }, (error) => {
      console.error(error);
      this.dialogRef.close({ error: true });
    });

  }

  ngOnInit() {
  }

}
