import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { AdminUtilComponent } from 'app/main/admin/admin-util/admin-util.component';
import { PagedDataSource } from '../iterfaces/paged-data-source';
import { Subscription } from 'rxjs';
import { UtilTableElement } from '../util-table/util-table-element';
import { UtilTableOptionElement } from '../util-table/util-table-option-element';

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss']
})
export class DynamicTableComponent extends AdminUtilComponent implements OnInit {
  filterKeys: string[];

  // Table variables
  displayedColumns: Array<string>;
  pageSizeOptions: Array<number> = [10, 20, 30];
  pageSize = 10;
  checkable: boolean = false;
  pageTitle: any;
  isInit: boolean = false;
  public _enableFilter: boolean;
  public _enableOrder: boolean;
  public _tableFields: UtilTableElement[] = [];
  public _actionOpts: UtilTableOptionElement[];
  dialogRef = null;

  initTable: (elements: UtilTableElement[]) => void;

  constructor(
    public dialog: MatDialog,
    snackBar: MatSnackBar,
    injector: Injector,
    @Inject(MAT_DIALOG_DATA) data
    ) {
    super(dialog, snackBar, injector);

    this._tableFields = data.displayedColumn;
    this.pageTitle = data.title;
    this.dataSource = data.dataSource;
    this.checkable = data.checkable;
    
   }
  

  ngAfterViewInit(): void {
    
  }

  ngOnInit() 
  {
    let displayedColumns = [];
    for(let i = 0; i < this._tableFields.length; i++) {
      displayedColumns.push(this._tableFields[i].matColumnDef);
    }
    this.displayedColumns = displayedColumns;
    this.isInit = true;

  }

  loadEntities(isExporting?: boolean): Subscription {
    let direction: 'ASC' | 'DESC' = 'ASC';
    if (this.sort && this.sort.direction) {
      if (this.sort.direction == 'asc')
        direction = 'ASC';
      else
        direction = 'DESC';
    }
    if (this.dataSource instanceof PagedDataSource) {
      let cust = {};
      
        
      return this.setLoadDataSubscription(this.dataSource.loadData(
        cust,
        this.filterKeys,
        this.filterValue,
        this.sort ? this.sort.active : null,
        direction,
        this.paginator.pageIndex,
        this.paginator.pageSize,
        isExporting));
    }
  }

  close()
  {
    this.dialog.closeAll();
  }
}
