import { Pipe, PipeTransform, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'userRoleFormatterPipe'
})
export class UserRoleFormatterPipe implements PipeTransform {

  constructor() { }

  transform(value, ...args: any[]): any {
    switch (value) {
      case 'OPERATORE':
        return 'SPECIALISTA'
        break;
      case 'USER':
        return 'UTENTE'
        break;
      case 'TENANT_ADMINISTRATOR':
        return 'ADMIN'
        break;
      default:
        return value;
        break;
    }
  }

}
